import { Buffer } from "buffer";

class Token {

    getLocalStorageJWTToken() {
        return localStorage.getItem('token') || null;
    }

    decodeJWTToken(token) {
        let decodedTokenParts = [], decodedToken = '';
        let tokenParts = token.split('.');

        tokenParts.forEach((item) => {
            decodedTokenParts.push(Buffer.from(item, 'base64').toString('ascii'))
        });

        decodedToken = decodedTokenParts.join(';');

        return decodedToken;
    }

    parseTokenPayload(decodedToken) {
        let tokenParts = decodedToken.split(';');
        return JSON.parse(tokenParts[1]);
    }
}

export default new Token();